/* eslint-disable react/display-name */
import React from "react";

import { SiteLink, MDXProvider as MyMDXProvider } from "@parataxic/shared-ui";

export const HSKMDXProvider = ({
  children,
  components = {},
  ...rest
}: React.ComponentProps<typeof MyMDXProvider>) => {
  return (
    <MyMDXProvider
      components={{
        ...components,
        a: ({ children, ...props }) => (
          <SiteLink {...props}>{children}</SiteLink>
        ),
      }}
      {...rest}
    >
      {children}
    </MyMDXProvider>
  );
};
